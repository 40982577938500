import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main">
      <h1>We are under construction</h1>
      <p>Please check back in a few weeks for a full gallery!</p>
      <ul className="actions">
        <li>
          <a href="/" className="button">
            Back to Home Page
          </a>
        </li>
      </ul>
    </div>
  </Layout>
)

export default NotFoundPage
